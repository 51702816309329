import { useContext } from "react";

import { AppContext, StatusEnum } from "../../../context/AppContext";
import { isGroupPage, isLocationProfile, isPage, isPersonProfile } from "../../../utils/location";
import { paths } from "../../../utils/paths";
import {
  AppreciatedSmileys,
  ConfusedSmileys,
  HappySmileys,
  HelpInstitutionSmileys,
  RedRequestsStatusSmiley,
} from "../../icons/Icons";

const Smileys = () => {
  const { people, person, itemRequestFormMounted } = useContext(AppContext);
  const isSearchResultEmpty = people.responseStatus === StatusEnum.Fulfilled && !people.peopleFound;
  const isRedStatus = person.entities?.requestsStatus === "red";

  const isHelpInstitutionsPage = isPage(paths.helpInstitutions);
  const isNotFound = isPage(paths.pageNotFound);
  const isCurrentTripsPage = isPage(paths.currentTrip);

  const isHappySmileys =
    (isPage(paths.search) && !isSearchResultEmpty) ||
    isPage(paths.main) ||
    isLocationProfile() ||
    isCurrentTripsPage ||
    isGroupPage();

  const showConfusedSmileys = isNotFound || isSearchResultEmpty;

  return (
    <>
      {isHelpInstitutionsPage && <HelpInstitutionSmileys className="smileys__icon-wider" />}
      {showConfusedSmileys && <ConfusedSmileys className="smileys__icon-regular" />}
      {itemRequestFormMounted && <AppreciatedSmileys className="smileys__icon-regular" />}
      {isHappySmileys && <HappySmileys className="smileys__icon-regular" />}
      {isRedStatus && isPersonProfile() && (
        <RedRequestsStatusSmiley className="smileys__icon-regular" />
      )}
    </>
  );
};

export default Smileys;
