import { FC, ReactElement } from "react";

import { TripNeeds } from "../../../types/Trip";
import { Book, Bread, Jar, Package, Paw, Soup, WaterDrop } from "../../icons/Icons";

interface NeedsIconProps {
  need: keyof TripNeeds;
  className?: string;
}

const NeedsIcon: FC<NeedsIconProps> = ({ need, className }) => {
  const needsToIconsMap: Record<keyof TripNeeds, ReactElement> = {
    hasBooks: <Book className={className} />,
    hasPackages: <Package className={className} />,
    hasProvisions: <Jar className={className} />,
    hasSoups: <Soup className={className} />,
    hasWaters: <WaterDrop className={className} />,
    hasAnimals: <Paw className={className} />,
    hasSandwiches: <Bread className={className} />,
  };

  return needsToIconsMap[need];
};

export default NeedsIcon;
