import { Translations } from "../types/Translations";

export const pl: Translations = {
  callModal: {
    callPerson: "Zadzwoń do osoby",
    hideNumberPrompt: "Możesz ukryć swój numer przed osobą, do której dzwonisz.",
    hideNumberButton: "Ukryj numer przed odbiorcą",
    hideNumberSuggested: "(Sugerowane)",
    callWithoutHidingButton: "Zadzwoń bez ukrywania",
    closeModal: "Zamknij",
  },
  navigation: {
    login: "Zaloguj",
    logout: "Wyloguj",
    visitZupaPage: "zupanaplantach.pl",
  },
  loginPage: {
    greeting: "Cześć!",
    description: "Podaj swoje imię i nazwisko oraz aktualny kod dostępu.",
    nameField: "Imię i Nazwisko",
    codeField: "Kod",
    enterButton: "Wchodzimy",
  },
  helpInstitutions: {
    header: "Punkty pomocy",
    disclaimer:
      "* Warunki uzyskania skierowania z MOPS - dana osoba musi być zarejestrowana w systemie opieki społecznej. Po skierowanie należy udać się do MOPS - Dział Pomocy Bezdomnym, os. Teatralne 24 w Nowej Hucie.",
  },
};
