import { NeedsList, TripGroup, TripGroupLocation, TripNeeds, TripNeedsCount } from "../types/Trip";

export const prepareNeedsList = (needs: TripNeeds, needsCount: TripNeedsCount): NeedsList => {
  const needsArray = Object.keys(needs).filter(
    (key) => needs[key as keyof TripNeeds] === true
  ) as Array<keyof TripNeeds>;
  return (
    needsCount &&
    needsArray.map((need) => ({ need: need, count: needsCount[needsToCountsMap[need]] }))
  );
};

export const needsToTextMap: Record<keyof TripNeeds, string> = {
  hasBooks: "Książki",
  hasPackages: "Paczka z Szafy",
  hasProvisions: "Prowiant",
  hasSoups: "Zupy",
  hasWaters: "Dodatkowa woda",
  hasAnimals: "Zwierzę",
  hasSandwiches: "Kanapki",
};

export const needsToCountsMap: Record<keyof TripNeeds, keyof TripNeedsCount> = {
  hasBooks: "bookCount",
  hasPackages: "packageCount",
  hasProvisions: "provisionCount",
  hasSoups: "soupCount",
  hasWaters: "waterCount",
  hasAnimals: "animalCount",
  hasSandwiches: "sandwichCount",
};

export const prepareNeedsObjects = (
  data: TripGroupLocation | TripGroup
): { needs: TripNeeds; needsCount: TripNeedsCount } => {
  const {
    hasBooks,
    hasPackages,
    hasProvisions,
    hasSoups,
    hasWaters,
    hasAnimals,
    hasSandwiches,
    bookCount,
    packageCount,
    provisionCount,
    soupCount,
    waterCount,
    animalCount,
    sandwichCount,
  } = data;
  const needs = {
    hasSoups,
    hasSandwiches,
    hasProvisions,
    hasWaters,
    hasBooks,
    hasPackages,
    hasAnimals,
  };
  const needsCount = {
    soupCount,
    sandwichCount,
    provisionCount,
    waterCount,
    bookCount,
    packageCount,
    animalCount,
  };
  return { needs, needsCount };
};

export const preparePersonCountText = (personCount: number): string => {
  if (personCount === 1) {
    return `${personCount} osoba`;
  } else if (personCount >= 2 && personCount <= 4) {
    return `${personCount} osoby`;
  } else {
    return `${personCount} osób`;
  }
};

export const prepareDestinationCountText = (destinationCount: number): string => {
  if (destinationCount === 1) {
    return `${destinationCount} miejsce`;
  } else if (destinationCount >= 2 && destinationCount <= 5) {
    return `${destinationCount} miejsca`;
  } else {
    return `${destinationCount} miejsc`;
  }
};

export const prepareVolunteerCountText = (volunteerCount: number): string => {
  if (volunteerCount === 1) {
    return `${volunteerCount} wolontariusz`;
  } else {
    return `${volunteerCount} wolontariuszy`;
  }
};
