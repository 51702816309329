export const TShirt = () => (
  <svg
    viewBox="0 0 30 31"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="category-icon"
  >
    <path
      d="M25.8489 3.06645L20.5297 0.546452C20.4648 0.515968 20.3942 0.5 20.3226 0.5H9.67742C9.60581 0.5 9.53516 0.515968 9.47032 0.546452L4.15113 3.06645C1.62919 4.26064 0 6.83532 0 9.62581V16.4677C0 16.7353 0.21629 16.9516 0.483871 16.9516H0.967742V18.4032C0.967742 18.6708 1.18403 18.8871 1.45161 18.8871H5.80645V30.0161C5.80645 30.2837 6.02274 30.5 6.29032 30.5H23.7097C23.9773 30.5 24.1935 30.2837 24.1935 30.0161V18.8871H28.5484C28.816 18.8871 29.0323 18.6708 29.0323 18.4032V16.9516H29.5161C29.7837 16.9516 30 16.7353 30 16.4677V9.62581C30 6.83532 28.3708 4.26064 25.8489 3.06645ZM15.4839 13.0806V29.5323H14.5161V13.0806C14.5161 12.9408 14.4218 12.7782 14.3163 12.6863C11.8215 10.5031 9.84581 7.87323 8.44403 4.86935L7.38677 2.60387L9.35129 1.67339L9.57048 2.54968C10.3035 5.48194 12.1185 7.97919 14.6811 9.58081L14.7431 9.61952C14.8219 9.66887 14.9105 9.69355 15 9.69355C15.0895 9.69355 15.1781 9.66887 15.2565 9.62L15.3184 9.58129C17.8815 7.97919 19.6965 5.48242 20.429 2.55016L20.6482 1.67387L22.6127 2.60435L21.5555 4.86984C20.1542 7.87371 18.1785 10.5035 15.6484 12.7173C15.5439 12.8087 15.4839 12.9413 15.4839 13.0806ZM10.2973 1.46774H19.7032L19.4913 2.315C18.8376 4.9279 17.2471 7.16387 15.0005 8.63581C12.7534 7.16435 11.1629 4.9279 10.5097 2.315L10.2973 1.46774ZM1.93548 17.9194V16.9516H5.80645V17.9194H1.93548ZM5.80645 9.69355V15.9839H0.967742V9.62581C0.967742 7.20742 2.37968 4.97629 4.56532 3.94081L6.51242 3.01855L7.56726 5.27871C8.99564 8.33968 10.9965 11.0276 13.5484 13.2989V29.5323H6.77419V9.69355H5.80645ZM28.0645 17.9194H24.1935V16.9516H28.0645V17.9194ZM29.0323 15.9839H24.1935V9.69355H23.2258V29.5323H16.4516V13.2994C18.9866 11.0503 20.9985 8.35226 22.4327 5.27871L23.4876 3.01855L25.4347 3.94081C27.6203 4.97581 29.0323 7.20742 29.0323 9.62581V15.9839Z"
      fill="#006653"
    />
  </svg>
);
