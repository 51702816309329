export const MagnifyingGlass = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8004 4.19999C7.15531 4.19999 4.20039 7.15491 4.20039 10.8C4.20039 14.4451 7.15531 17.4 10.8004 17.4C12.6232 17.4 14.2719 16.6622 15.4673 15.4669C16.6626 14.2715 17.4004 12.6228 17.4004 10.8C17.4004 7.15491 14.4455 4.19999 10.8004 4.19999ZM2.40039 10.8C2.40039 6.1608 6.1612 2.39999 10.8004 2.39999C15.4396 2.39999 19.2004 6.1608 19.2004 10.8C19.2004 12.795 18.504 14.6287 17.3425 16.0693L21.3368 20.0636C21.6883 20.4151 21.6883 20.9849 21.3368 21.3364C20.9853 21.6879 20.4155 21.6879 20.064 21.3364L16.0697 17.3421C14.6291 18.5036 12.7954 19.2 10.8004 19.2C6.1612 19.2 2.40039 15.4392 2.40039 10.8Z"
      fill="#0F172A"
    />
  </svg>
);
