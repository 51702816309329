export const Other = () => (
  <svg
    viewBox="0 0 36 9"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="category-icon"
  >
    <path
      d="M31.4412 8.99969C29.0977 8.99969 27.1914 7.09251 27.1914 4.74817C27.1914 2.40469 29.0979 0.497559 31.4412 0.497559C33.7855 0.497559 35.6927 2.40473 35.6927 4.74817C35.6927 7.09161 33.7855 8.99969 31.4412 8.99969ZM31.4412 2.19726C30.0348 2.19726 28.892 3.34179 28.892 4.74817C28.892 6.15455 30.0357 7.29908 31.4412 7.29908C32.8475 7.29908 33.9921 6.15455 33.9921 4.74817C33.9921 3.34179 32.8477 2.19726 31.4412 2.19726Z"
      fill="#006653"
    />
    <path
      d="M17.8453 8.99969C15.5009 8.99969 13.5938 7.09251 13.5938 4.74817C13.5938 2.40469 15.5009 0.497559 17.8453 0.497559C20.1896 0.497559 22.0968 2.40473 22.0968 4.74817C22.0968 7.09161 20.1887 8.99969 17.8453 8.99969ZM17.8453 2.19726C16.4389 2.19726 15.2944 3.34093 15.2944 4.74726C15.2944 6.1536 16.4389 7.29817 17.8453 7.29817C19.2516 7.29817 20.3962 6.15364 20.3962 4.74726C20.3962 3.34179 19.2518 2.19726 17.8453 2.19726Z"
      fill="#006653"
    />
    <path
      d="M4.24816 8.99969C1.90559 8.99969 0 7.09251 0 4.74817C0 2.40469 1.90559 0.497559 4.24816 0.497559C6.59251 0.497559 8.49968 2.40473 8.49968 4.74817C8.49968 7.09161 6.59232 8.99969 4.24816 8.99969ZM4.24816 2.19726C2.84337 2.19726 1.70061 3.34093 1.70061 4.74726C1.70061 6.1536 2.84337 7.29817 4.24816 7.29817C5.65454 7.29817 6.79907 6.15364 6.79907 4.74726C6.79907 3.34179 5.6554 2.19726 4.24816 2.19726Z"
      fill="#006653"
    />
  </svg>
);
