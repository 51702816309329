interface AvatarProps {
  size?: string;
}

export const Avatar = ({ size }: AvatarProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="#006653"
    />
    <path
      d="M7 40.94V26C7 21.4913 8.79107 17.1673 11.9792 13.9792C15.1673 10.7911 19.4913 9 24 9C28.5087 9 32.8327 10.7911 36.0208 13.9792C39.2089 17.1673 41 21.4913 41 26V40.94C38.7703 43.1774 36.1209 44.9527 33.2036 46.1641C30.2864 47.3754 27.1587 47.9989 24 47.9989C20.8413 47.9989 17.7136 47.3754 14.7964 46.1641C11.8791 44.9527 9.22968 43.1774 7 40.94Z"
      fill="#FDD051"
    />
    <path
      d="M18.7205 24C19.6814 24 20.4605 23.221 20.4605 22.26C20.4605 21.299 19.6814 20.52 18.7205 20.52C17.7595 20.52 16.9805 21.299 16.9805 22.26C16.9805 23.221 17.7595 24 18.7205 24Z"
      fill="#191919"
    />
    <path
      d="M29.28 24C30.241 24 31.02 23.221 31.02 22.26C31.02 21.299 30.241 20.52 29.28 20.52C28.3191 20.52 27.54 21.299 27.54 22.26C27.54 23.221 28.3191 24 29.28 24Z"
      fill="#191919"
    />
    <path
      d="M23.9998 27.22C23.362 27.1929 22.7494 26.9629 22.2512 26.5637C21.753 26.1644 21.3952 25.6166 21.2298 25C21.2121 24.9343 21.2075 24.8658 21.2162 24.7984C21.225 24.7309 21.2469 24.6659 21.2808 24.6069C21.3147 24.5479 21.3599 24.4962 21.4138 24.4547C21.4676 24.4132 21.5292 24.3827 21.5948 24.365C21.6605 24.3473 21.729 24.3426 21.7964 24.3514C21.8639 24.3601 21.9289 24.3821 21.9879 24.416C22.0469 24.4499 22.0986 24.4951 22.1401 24.5489C22.1816 24.6028 22.2121 24.6643 22.2298 24.73C22.3405 25.1277 22.5687 25.4827 22.8845 25.7486C23.2003 26.0144 23.5891 26.1787 23.9998 26.22C24.4197 26.1825 24.8179 26.0164 25.14 25.7444C25.4621 25.4724 25.6925 25.1077 25.7998 24.7C25.8356 24.5674 25.9226 24.4544 26.0417 24.386C26.1608 24.3175 26.3022 24.2992 26.4348 24.335C26.5674 24.3708 26.6804 24.4578 26.7488 24.5769C26.8173 24.696 26.8356 24.8374 26.7998 24.97C26.6384 25.5969 26.2784 26.1548 25.7738 26.5604C25.2691 26.9659 24.6468 27.1973 23.9998 27.22Z"
      fill="#191919"
    />
  </svg>
);
