export const Socks = () => (
  <svg
    viewBox="0 0 28 33"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="category-icon"
  >
    <path
      d="M27.1246 0.093689H22.2027V3.37494H23.2965V1.18744H24.3902V3.37494H25.484V1.18744H26.5777V4.46869H22.0432V5.56244H26.5777V7.20306H21.9977V8.29681H26.5777V9.93744H22.0432V11.0312H26.5777V16.5985L22.5125 17.4115L22.727 18.484L26.5776 17.7139V18.7422C26.5801 19.613 26.234 20.4484 25.6166 21.0625L24.6024 22.0767L22.7177 18.8689L21.7743 19.4231L23.803 22.8758L16.4603 30.2188L14.5455 28.3041L13.7722 29.0775L15.6331 30.9385C14.9957 31.3823 14.2613 31.6674 13.4913 31.7699C12.7214 31.8725 11.938 31.7894 11.2066 31.5278L10.837 32.5572H10.8369C11.9009 32.9381 13.0512 33.0089 14.154 32.7616C15.2567 32.5143 16.2665 31.9589 17.0659 31.1602L24.8759 23.3502L26.39 21.8362C27.2131 21.0174 27.6745 19.9033 27.6712 18.7425V0.64106C27.6712 0.496023 27.6136 0.356912 27.511 0.254368C27.4085 0.15183 27.2694 0.094179 27.1243 0.094179L27.1246 0.093689Z"
      fill="#006653"
    />
    <path
      d="M19.828 21.836C20.6512 21.0172 21.1125 19.9031 21.1093 18.7423V0.640875C21.1093 0.495838 21.0517 0.356727 20.9491 0.254183C20.8466 0.151645 20.7075 0.0939941 20.5624 0.0939941H9.62494C9.4799 0.0939941 9.34079 0.151645 9.23825 0.254183C9.13571 0.356722 9.07806 0.495835 9.07806 0.640875V15.1799L2.07427 22.1837C1.51896 22.7358 1.07872 23.3927 0.778974 24.1162C0.479345 24.8397 0.326103 25.6154 0.328145 26.3985V26.9454C0.328259 28.5262 0.95625 30.0423 2.07415 31.1602C3.19205 32.278 4.70816 32.906 6.28903 32.906C7.86983 32.906 9.38594 32.278 10.5039 31.1602L18.3139 23.3503L19.828 21.836ZM20.0156 18.7423C20.0181 19.6131 19.672 20.4485 19.0546 21.0626L18.0404 22.0768L15.9544 18.5263L20.0156 17.7141L20.0156 18.7423ZM10.1718 5.56246H20.0156V7.20308H10.1718V5.56246ZM10.1718 8.29683H20.0156V9.93746H10.1718V8.29683ZM11.2656 1.18746V3.37496H12.3593V1.18746H13.4531V3.37496H14.5468V1.18746H15.6406V3.37496H16.7343V1.18746H17.8281V3.37496H18.9218V1.18746H20.0156V4.46871H10.1718V1.18746H11.2656ZM2.84748 30.3862C2.39402 29.9354 2.03457 29.3991 1.78984 28.8084C1.54522 28.2176 1.42012 27.5843 1.42196 26.9449V26.398C1.42013 25.5373 1.64811 24.692 2.0822 23.9488L9.07141 30.938C8.1342 31.5913 6.99717 31.894 5.85929 31.793C4.72144 31.6922 3.65528 31.1941 2.84783 30.3862H2.84748ZM9.89852 30.2184L2.74481 23.0647C2.77899 23.0285 2.81237 22.9919 2.84735 22.9566L10.0113 15.7926C10.1139 15.6901 10.1717 15.551 10.1718 15.4059V11.0309H20.0155V16.5983L14.9863 17.6041C14.8127 17.6388 14.6666 17.7553 14.5943 17.9168C14.5221 18.0784 14.5323 18.265 14.6221 18.4176L17.2413 22.8754L9.89852 30.2184Z"
      fill="#006653"
    />
  </svg>
);
