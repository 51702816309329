export const Tent = () => (
  <svg
    viewBox="0 0 36 21"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="category-icon"
  >
    <path
      d="M26.1182 0.500084C26.2938 0.500084 26.4494 0.637366 26.5045 0.840984C28.4881 8.16352 31.5855 14.2909 35.3977 19.6712L35.398 19.6715C35.4699 19.771 35.5063 19.9013 35.4991 20.034C35.4918 20.1664 35.4415 20.29 35.3595 20.3769C35.2772 20.4638 35.1702 20.507 35.0619 20.4967C34.9535 20.4867 34.853 20.424 34.7828 20.3227C34.046 19.283 33.3353 18.2143 32.653 17.1147V19.997C32.653 20.2718 32.4707 20.4944 32.246 20.4944H3.92202C3.6974 20.4944 3.51526 20.2718 3.51526 19.997V17.0148C2.78396 18.1534 2.01503 19.2586 1.21138 20.3327C1.13902 20.4325 1.03702 20.4924 0.928072 20.4993C0.819337 20.5063 0.712702 20.4595 0.632358 20.3695C0.551804 20.2795 0.50427 20.1541 0.500275 20.0209C0.496278 19.8877 0.53603 19.7581 0.610906 19.6612C4.63479 14.284 7.76231 8.16735 9.49658 0.858636C9.54706 0.646279 9.70627 0.5 9.88736 0.5L26.1182 0.500084ZM25.6069 1.49474H10.1911C8.94349 6.59386 7.02642 11.1234 4.61679 15.2224H19.6793C22.5509 11.7566 24.6358 7.54047 25.6069 1.49474ZM26.4925 3.73731V19.4999H31.8392V15.7639C29.7117 12.1281 27.8963 8.15242 26.4925 3.73757V3.73731ZM25.6788 5.16595C24.5254 9.57941 22.6739 12.9795 20.3282 15.8365V19.4997H25.6788V5.16595ZM19.5143 16.2169H4.32905V19.4996H19.5143V16.2169Z"
      fill="#006653"
    />
  </svg>
);
