export const Hat = () => (
  <svg
    viewBox="0 0 28 29"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="category-icon"
  >
    <path
      d="M6.31738 14.6575H5.44238C5.44238 9.93252 9.29238 6.11752 13.9824 6.11752V6.99252C9.78238 6.99264 6.31738 10.4225 6.31738 14.6575Z"
      fill="#006653"
    />
    <path
      d="M5.47777 17.7725H5.26779C4.95276 17.7725 4.67285 18.0526 4.67285 18.3675V27.7475C4.67285 28.0625 4.9529 28.3424 5.26779 28.3424H5.47777C5.7928 28.3424 6.07271 28.0624 6.07271 27.7475V18.3675C6.07271 18.0525 5.82776 17.7724 5.47777 17.7724V17.7725Z"
      fill="#006653"
    />
    <path
      d="M9.78246 17.7725H9.57248C9.25745 17.7725 8.97754 18.0526 8.97754 18.3675V27.7475C8.97754 28.0625 9.25759 28.3424 9.57248 28.3424H9.78246C10.0975 28.3424 10.3774 28.0624 10.3774 27.7475L10.3775 18.3675C10.3775 18.0525 10.0975 17.7724 9.78245 17.7724L9.78246 17.7725Z"
      fill="#006653"
    />
    <path
      d="M14.053 17.7725H13.843C13.528 17.7725 13.248 18.0526 13.248 18.3675V27.7475C13.248 28.0625 13.5281 28.3424 13.843 28.3424H14.053C14.368 28.3424 14.6479 28.0624 14.6479 27.7475V18.3675C14.6479 18.0525 14.403 17.7724 14.053 17.7724V17.7725Z"
      fill="#006653"
    />
    <path
      d="M18.3225 17.7725H18.1125C17.7975 17.7725 17.5176 18.0526 17.5176 18.3675V27.7475C17.5176 28.0625 17.7976 28.3424 18.1125 28.3424H18.3225C18.6375 28.3424 18.9174 28.0624 18.9174 27.7475V18.3675C18.9174 18.0525 18.6725 17.7724 18.3225 17.7724V17.7725Z"
      fill="#006653"
    />
    <path
      d="M22.6282 17.7725H22.4182C22.1032 17.7725 21.8232 18.0526 21.8232 18.3675V27.7475C21.8232 28.0625 22.1033 28.3424 22.4182 28.3424H22.6282C22.9432 28.3424 23.2231 28.0624 23.2231 27.7475V18.3675C23.2231 18.0525 22.9781 17.7724 22.6282 17.7724V17.7725Z"
      fill="#006653"
    />
    <path
      d="M25.7077 17.5975V14.8675C25.7077 9.75748 22.4177 5.41748 17.8327 3.84248C17.9027 3.6325 17.9378 3.38742 17.9378 3.14248C17.9378 1.49754 16.6077 0.132477 14.9278 0.132477H13.0727C11.4278 0.132477 10.0627 1.46254 10.0627 3.14248C10.0627 3.38743 10.0977 3.63251 10.1678 3.84248C5.58277 5.41748 2.29277 9.75748 2.29277 14.8675V17.5975C1.34783 18.0526 0.717773 18.9975 0.717773 20.0825V26.0675C0.717773 27.6075 1.97774 28.8675 3.51777 28.8675H24.4828C26.0228 28.8675 27.2828 27.6075 27.2828 26.0675L27.2827 20.1175C27.2827 18.9974 26.6176 18.0525 25.7077 17.5974L25.7077 17.5975ZM11.4977 3.14254C11.4977 2.26754 12.1977 1.56754 13.0727 1.56754H14.9278C15.8028 1.56754 16.5028 2.26754 16.5028 3.14254C16.5028 3.24758 16.4678 3.35251 16.4678 3.45756C15.6628 3.28256 14.8579 3.17752 13.9829 3.17752C13.1428 3.17752 12.3028 3.28256 11.4979 3.45756C11.5327 3.35252 11.4978 3.24747 11.4978 3.14254H11.4977ZM3.72774 14.8675C3.72774 9.19753 8.34774 4.57753 14.0177 4.57753C19.6877 4.57753 24.3077 9.19753 24.3077 14.8675V17.3175H3.72774V14.8675ZM25.8827 26.1025C25.8827 26.8725 25.2527 27.4676 24.5177 27.4676H3.55271C2.78277 27.4676 2.18768 26.8375 2.18768 26.1025V20.1175C2.18768 19.3476 2.81774 18.7525 3.55271 18.7525H24.5177C25.2877 18.7525 25.8827 19.3826 25.8827 20.1175V26.1025Z"
      fill="#006653"
    />
  </svg>
);
