export const ThumbUpIcon = () => (
  <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.86601 23.125C9.33529 23.125 8.8412 22.8294 8.64699 22.3355C8.22936 21.2734 8 20.1166 8 18.9062C8 16.7199 8.74842 14.7083 10.003 13.1138C10.1917 12.8741 10.4682 12.7276 10.7527 12.6175C11.3437 12.3886 11.8657 11.9746 12.2676 11.4621C13.2341 10.2298 14.4521 9.20424 15.8444 8.46277C16.7471 7.98204 17.5304 7.26785 17.9102 6.3183C18.176 5.65387 18.3125 4.94483 18.3125 4.22922V3.4375C18.3125 2.91973 18.7322 2.5 19.25 2.5C20.8033 2.5 22.0625 3.7592 22.0625 5.3125C22.0625 6.75204 21.738 8.11579 21.1583 9.33463C20.8263 10.0326 21.292 10.9375 22.0649 10.9375H25.9722C27.2555 10.9375 28.4041 11.805 28.54 13.0811C28.5962 13.6089 28.625 14.1448 28.625 14.6875C28.625 18.247 27.3852 21.517 25.3138 24.089C24.8292 24.6906 24.0802 25 23.3078 25H18.2878C17.6832 25 17.0826 24.9025 16.509 24.7113L12.616 23.4137C12.0424 23.2225 11.4418 23.125 10.8372 23.125H9.86601Z"
      fill="#005142"
    />
    <path
      d="M3.41436 13.4089C2.74351 15.1112 2.375 16.9657 2.375 18.9062C2.375 20.4313 2.6026 21.9033 3.0257 23.2899C3.34989 24.3525 4.38022 25 5.49111 25H6.62584C7.18275 25 7.52671 24.3772 7.28029 23.8778C6.54059 22.3786 6.125 20.691 6.125 18.9062C6.125 16.771 6.71985 14.7747 7.75288 13.0741C8.05913 12.5699 7.71755 11.875 7.12765 11.875H5.81169C4.77167 11.875 3.79568 12.4413 3.41436 13.4089Z"
      fill="#005142"
    />
  </svg>
);
