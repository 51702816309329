export const NoResult = () => (
  <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="28" height="26" rx="13" fill="#FFCBCB" />
    <path
      d="M19.273 17.773C21.909 15.1369 21.909 10.8631 19.273 8.22703C16.6369 5.59099 12.3631 5.59099 9.72703 8.22703M19.273 17.773C16.6369 20.409 12.3631 20.409 9.72703 17.773C7.09099 15.1369 7.09099 10.8631 9.72703 8.22703M19.273 17.773L9.72703 8.22703"
      stroke="#8E3737"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
