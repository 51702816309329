export const HamburgerIcon = () => (
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector"
      d="M0.75 0.75H17.25M0.75 6H17.25M0.75 11.25H17.25"
      stroke="#191919"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
