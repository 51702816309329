export const HidePhoneNumber = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.5651 6.20891V3.99148C20.5651 2.5219 19.3737 1.33057 17.9042 1.33057C16.4346 1.33057 15.2432 2.5219 15.2432 3.99148V6.20891M14.7998 12.8612H21.0086C21.7434 12.8612 22.339 12.2655 22.339 11.5307V7.53937C22.339 6.80458 21.7434 6.20891 21.0086 6.20891H14.7998C14.065 6.20891 13.4693 6.80458 13.4693 7.53937V11.5307C13.4693 12.2655 14.065 12.8612 14.7998 12.8612Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0206 23.8755C12.4306 22.9554 9.15394 21.0873 6.53339 18.4668C3.91285 15.8462 2.04477 12.5695 1.12464 8.97956C0.421516 6.21737 2.79667 3.8706 5.64788 3.8706L6.93802 3.8706C7.65018 3.8706 8.22171 4.44988 8.29267 5.15817C8.40846 6.33287 8.68588 7.48596 9.11707 8.58478L7.15605 10.5458C8.68661 13.7388 11.2614 16.3136 14.4544 17.8441L16.4154 15.8831C17.5142 16.3147 18.6673 16.5926 19.842 16.7088C20.5516 16.7785 21.1296 17.35 21.1296 18.0622L21.1296 19.3523C21.1296 22.2035 18.7828 24.5787 16.0206 23.8755Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
