export const Parcel = () => (
  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 10.25V18.5C20 18.8978 19.842 19.2794 19.5607 19.5607C19.2794 19.842 18.8978 20 18.5 20H4.25C3.85218 20 3.47064 19.842 3.18934 19.5607C2.90804 19.2794 2.75 18.8978 2.75 18.5V10.25M11 3.875C11 3.35583 10.846 2.84831 10.5576 2.41663C10.2692 1.98495 9.8592 1.6485 9.37954 1.44982C8.89989 1.25114 8.37209 1.19915 7.86289 1.30044C7.35369 1.40173 6.88596 1.65173 6.51884 2.01885C6.15173 2.38596 5.90172 2.85369 5.80044 3.36289C5.69915 3.87209 5.75114 4.39989 5.94982 4.87954C6.1485 5.3592 6.48495 5.76917 6.91663 6.05761C7.34831 6.34605 7.85582 6.5 8.375 6.5H11M11 3.875V6.5M11 3.875C11 3.35583 11.154 2.84831 11.4424 2.41663C11.7308 1.98495 12.1408 1.6485 12.6205 1.44982C13.1001 1.25114 13.6279 1.19915 14.1371 1.30044C14.6463 1.40173 15.114 1.65173 15.4812 2.01885C15.8483 2.38596 16.0983 2.85369 16.1996 3.36289C16.3008 3.87209 16.2489 4.39989 16.0502 4.87954C15.8515 5.3592 15.5151 5.76917 15.0834 6.05761C14.6517 6.34605 14.1442 6.5 13.625 6.5H11M11 6.5V20M2.375 10.25H20.375C20.996 10.25 21.5 9.746 21.5 9.125V7.625C21.5 7.004 20.996 6.5 20.375 6.5H2.375C1.754 6.5 1.25 7.004 1.25 7.625V9.125C1.25 9.746 1.754 10.25 2.375 10.25Z"
      stroke="#191919"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
