export const Phone = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.04 19.506C13.2574 18.7928 10.7176 17.3448 8.68637 15.3136C6.65516 13.2824 5.2072 10.7426 4.494 7.96C3.949 5.819 5.79 4 8 4L9 4C9.552 4 9.995 4.449 10.05 4.998C10.1397 5.90852 10.3548 6.80229 10.689 7.654L9.169 9.174C10.3553 11.6489 12.3511 13.6447 14.826 14.831L16.346 13.311C17.1977 13.6456 18.0914 13.8609 19.002 13.951C19.552 14.005 20 14.448 20 15L20 16C20 18.21 18.181 20.051 16.04 19.506Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
