export const Underwear = () => (
  <svg
    viewBox="0 0 32 25"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="category-icon"
  >
    <path
      d="M18.5901 24.085H13.4101C13.13 24.085 12.92 23.875 12.8851 23.595C12.8851 23.56 12.6401 19.85 11.03 16.21C8.86006 11.415 5.43 8.99998 0.775 8.99998C0.494953 8.99998 0.25 8.75502 0.25 8.47498V1.43998C0.25 1.15993 0.494953 0.914978 0.775 0.914978H31.225C31.505 0.914978 31.75 1.15993 31.75 1.43998V8.47498C31.75 8.75502 31.505 8.99998 31.225 8.99998C26.57 8.99998 23.105 11.45 20.97 16.245C19.3251 19.885 19.1149 23.595 19.1149 23.63C19.1151 23.875 18.87 24.085 18.5901 24.085V24.085ZM13.8651 23.035H18.1001C18.2051 21.8799 18.6251 18.87 19.9901 15.79C22.2301 10.855 25.9401 8.12501 30.7001 7.95001V1.96501H1.30009V7.95001C6.09509 8.12501 9.77009 10.8199 11.9751 15.79C13.3401 18.87 13.7601 21.88 13.8651 23.035H13.8651Z"
      fill="#006653"
    />
    <path d="M2.31543 3.435H3.36543V8.65H2.31543V3.435Z" fill="#006653" />
    <path d="M28.6348 3.75H29.6848V8.615H28.6348V3.75Z" fill="#006653" />
    <path d="M0.775391 2.91003H31.2254V3.96003H0.775391V2.91003Z" fill="#006653" />
    <path
      d="M13.0952 23.98C4.52016 17.75 0.355156 8.75502 0.285156 8.68502L1.2301 8.26508C1.26507 8.37012 5.3601 17.0851 13.6901 23.1751L13.0952 23.98Z"
      fill="#006653"
    />
    <path
      d="M18.9055 23.98L18.3105 23.14C26.6755 17.085 30.7355 8.33498 30.7705 8.22998L31.7155 8.64992C31.6454 8.75497 27.4805 17.7499 18.9055 23.9799V23.98Z"
      fill="#006653"
    />
  </svg>
);
