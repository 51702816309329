import { FC, MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";

import { paths } from "../../../utils/paths";
import Card from "../../atoms/Card/Card";
import { Avatar } from "../../icons/Icons";

type PersonCardProps = {
  name: string;
  personCode: string;
  locationFullName: string;
  personId: string;
};

const PersonCard: FC<PersonCardProps> = ({ name, personCode, locationFullName, personId }) => {
  const navigate = useNavigate();

  const handleClick: MouseEventHandler = () => {
    navigate(paths.personProfile(personId));
  };

  return (
    <div tabIndex={0} role="button" onClick={handleClick}>
      <Card
        header={name}
        info={`#${personCode}`}
        content={
          <>
            <p>{locationFullName}</p>
          </>
        }
        icon={<Avatar size="24px" />}
      />
    </div>
  );
};
export default PersonCard;
