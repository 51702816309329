interface BreadProps {
  className?: string;
}

export const Bread = ({ className }: BreadProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`bread ${className}`}
  >
    <path
      d="M11.25 12C11.25 12.4142 11.5858 12.75 12 12.75C12.4142 12.75 12.75 12.4142 12.75 12H11.25ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8H7.75ZM6.25 12C6.25 12.4142 6.58579 12.75 7 12.75C7.41421 12.75 7.75 12.4142 7.75 12H6.25ZM17.75 8C17.75 7.58579 17.4142 7.25 17 7.25C16.5858 7.25 16.25 7.58579 16.25 8H17.75ZM16.25 12C16.25 12.4142 16.5858 12.75 17 12.75C17.4142 12.75 17.75 12.4142 17.75 12H16.25ZM21.25 16V16.1538H22.75V16H21.25ZM20.1538 17.25H3.84615V18.75H20.1538V17.25ZM2.75 16.1538V16H1.25V16.1538H2.75ZM3.84615 17.25C3.24076 17.25 2.75 16.7592 2.75 16.1538H1.25C1.25 17.5877 2.41234 18.75 3.84615 18.75V17.25ZM21.25 16.1538C21.25 16.7592 20.7592 17.25 20.1538 17.25V18.75C21.5877 18.75 22.75 17.5877 22.75 16.1538H21.25ZM12 6.75C17.1086 6.75 21.25 10.8914 21.25 16H22.75C22.75 10.0629 17.9371 5.25 12 5.25V6.75ZM12 5.25C6.06294 5.25 1.25 10.0629 1.25 16H2.75C2.75 10.8914 6.89137 6.75 12 6.75V5.25ZM11.25 6V12H12.75V6H11.25ZM6.25 8V12H7.75V8H6.25ZM16.25 8V12H17.75V8H16.25Z"
      fill="#191919"
    />
  </svg>
);
