interface LocationPinAvatarProps {
  className?: string;
}

export const LocationPinAvatar = ({ className }: LocationPinAvatarProps) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_2757_12893)">
        <g clipPath="url(#clip1_2757_12893)">
          <path
            d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
            fill="#93CFC4"
          />
          <path
            d="M15.8284 17.9835L15.8206 17.9831C15.0933 17.9522 14.3948 17.69 13.8267 17.2347C13.2587 16.7795 12.8507 16.1549 12.662 15.4518C12.662 15.4518 12.662 15.4517 12.662 15.4517L12.8552 15.3998C12.8361 15.3294 12.8312 15.2558 12.8406 15.1834C12.8499 15.111 12.8735 15.0411 12.9099 14.9778C12.9463 14.9145 12.9948 14.859 13.0526 14.8144C13.1105 14.7699 13.1765 14.7371 13.247 14.7181C13.3175 14.6991 13.3911 14.6941 13.4635 14.7035C13.5359 14.7129 13.6058 14.7364 13.6691 14.7728C13.7324 14.8092 13.7879 14.8577 13.8325 14.9156C13.877 14.9734 13.9098 15.0395 13.9288 15.11L15.8284 17.9835ZM15.8284 17.9835L15.8361 17.9832C16.5739 17.9573 17.2835 17.6934 17.859 17.231C18.4341 16.7688 18.8444 16.1331 19.0287 15.4186C19.0805 15.2254 19.0537 15.0194 18.954 14.8459C18.854 14.6721 18.6891 14.5451 18.4956 14.4928C18.302 14.4405 18.0956 14.4673 17.9217 14.5672C17.7479 14.6672 17.6208 14.832 17.5686 15.0256L17.5683 15.0268C17.4635 15.4248 17.2386 15.7808 16.9242 16.0463C16.6146 16.3077 16.2331 16.4688 15.8302 16.5087C15.4363 16.4653 15.0639 16.3059 14.7605 16.0505C14.4524 15.7912 14.2298 15.4449 14.1217 15.057L15.8284 17.9835Z"
            fill="#006653"
            stroke="#006653"
            strokeWidth="0.4"
          />
          <path
            d="M15.9999 25.3333C15.9999 25.3333 23.7333 21.0977 23.7333 13.7333C23.7333 9.46233 20.2709 6 15.9999 6C11.7289 6 8.2666 9.46233 8.2666 13.7333C8.2666 21.0977 15.9999 25.3333 15.9999 25.3333Z"
            stroke="#006653"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2757_12893">
          <rect width="32" height="32" fill="white" />
        </clipPath>
        <clipPath id="clip1_2757_12893">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
