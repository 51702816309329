export const LocationPinCard = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#93CFC4" />
    <path
      d="M11.8361 14.096L11.8361 14.0956C11.172 14.0674 10.5341 13.828 10.0154 13.4122C9.4967 12.9965 9.12413 12.4262 8.95188 11.7841C8.95187 11.7841 8.95186 11.7841 8.95185 11.784L9.07258 11.7517C9.05486 11.686 9.05024 11.6175 9.05899 11.5501C9.06773 11.4827 9.08967 11.4177 9.12355 11.3587C9.15743 11.2998 9.20259 11.2481 9.25644 11.2066C9.3103 11.1651 9.3718 11.1346 9.43743 11.1169C9.50307 11.0992 9.57155 11.0946 9.63897 11.1033C9.70639 11.1121 9.77143 11.134 9.83037 11.1679C9.88931 11.2018 9.941 11.2469 9.98249 11.3008C10.024 11.3546 10.0545 11.4161 10.0722 11.4818L11.8361 14.096ZM11.8361 14.096L11.8458 14.0957C12.5195 14.072 13.1675 13.8311 13.693 13.4088C14.2183 12.9867 14.593 12.4061 14.7612 11.7535C14.8053 11.5892 14.7825 11.414 14.6977 11.2664C14.6128 11.1187 14.4726 11.0107 14.308 10.9663C14.1435 10.9218 13.968 10.9446 13.8202 11.0295C13.6724 11.1145 13.5645 11.2546 13.52 11.4192L13.5198 11.42C13.4191 11.8026 13.2028 12.1449 12.9005 12.4002C12.6012 12.653 12.2319 12.8083 11.8421 12.8456C11.461 12.8049 11.1005 12.6513 10.8071 12.4043C10.5108 12.1548 10.2967 11.8218 10.1927 11.4487L11.8361 14.096Z"
      fill="#006653"
      stroke="#006653"
      strokeWidth="0.25"
    />
    <path
      d="M11.9998 21C11.9998 21 19.1998 17.0564 19.1998 10.2C19.1998 6.22355 15.9763 3 11.9998 3C8.02335 3 4.7998 6.22355 4.7998 10.2C4.7998 17.0564 11.9998 21 11.9998 21Z"
      stroke="#006653"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
