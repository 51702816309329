import { FC } from "react";

import { NeedsList } from "../../../types/Trip";
import NeedsIcon from "../NeedsIcon/NeedsIcon";

interface TagWithNeedsIconsProps {
  tagData: string;
  icon?: JSX.Element;
  needs: NeedsList;
  className?: string;
}

const TagWithNeedsIcons: FC<TagWithNeedsIconsProps> = ({ tagData, icon, needs, className }) => {
  return (
    <div className={`tag-with-needs ${className}`}>
      <div className="tag-with-needs__data">
        {icon && <div className="tag-with-needs__icon">{icon}</div>}
        <div className="tag-with-needs__text">
          <span>{tagData}</span>
        </div>
      </div>
      {needs.length > 0 &&
        needs.map((need) => {
          return (
            <div className="tag-with-needs__need" key={`${need.need}-icon-with-count`}>
              <NeedsIcon need={need.need} />
              <span>{need.count}</span>
            </div>
          );
        })}
    </div>
  );
};

export default TagWithNeedsIcons;
