export const Lifebuoy = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7116 2.33001C15.3114 2.69941 15.8658 3.13772 16.3636 3.63602C16.8736 4.14602 17.3076 4.70002 17.6696 5.28802M14.7116 2.33001L11.2636 6.46802M14.7116 2.33001C13.2937 1.46056 11.6629 1.00037 9.99965 1.00037C8.33639 1.00037 6.70556 1.46056 5.28765 2.33001M17.6696 5.28802L13.5316 8.73602M17.6696 5.28802C18.5391 6.70593 18.9993 8.33676 18.9993 10C18.9993 11.6633 18.5391 13.2941 17.6696 14.712M13.5316 8.73602C13.345 8.21377 13.0444 7.73963 12.6516 7.34801C12.26 6.95532 11.7859 6.65472 11.2636 6.46802M13.5316 8.73602C13.823 9.5535 13.823 10.4465 13.5316 11.264M11.2636 6.46802C10.4462 6.17666 9.55313 6.17666 8.73565 6.46802M13.5316 11.264C13.3506 11.77 13.0566 12.246 12.6516 12.652C12.26 13.0447 11.7859 13.3453 11.2636 13.532M13.5316 11.264L17.6696 14.712M17.6696 14.712C17.3003 15.3117 16.8619 15.8662 16.3636 16.364C15.8536 16.874 15.2996 17.308 14.7116 17.67M14.7116 17.67L11.2636 13.532M14.7116 17.67C13.2937 18.5395 11.6629 18.9997 9.99965 18.9997C8.33639 18.9997 6.70556 18.5395 5.28765 17.67M11.2636 13.532C10.4462 13.8234 9.55313 13.8234 8.73565 13.532M8.73565 13.532C8.2134 13.3453 7.73926 13.0447 7.34765 12.652C6.95495 12.2604 6.65435 11.7862 6.46765 11.264M8.73565 13.532L5.28765 17.67M5.28765 17.67C4.6879 17.3006 4.13346 16.8623 3.63565 16.364C3.13735 15.8662 2.69904 15.3117 2.32965 14.712M2.32965 14.712L6.46765 11.264M2.32965 14.712C1.46019 13.2941 1 11.6633 1 10C1 8.33676 1.46019 6.70593 2.32965 5.28802M6.46765 11.264C6.17629 10.4465 6.17629 9.5535 6.46765 8.73602M6.46765 8.73602C6.64865 8.23002 6.94265 7.75401 7.34765 7.34801C7.73926 6.95529 8.2134 6.65469 8.73565 6.46802M6.46765 8.73602L2.32965 5.28802M8.73565 6.46802L5.28765 2.33001M5.28765 2.33001C4.6879 2.69938 4.13346 3.1377 3.63565 3.63602C3.13734 4.13383 2.69902 4.68827 2.32965 5.28802"
      stroke="#191919"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
